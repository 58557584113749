import React from 'react';

export const MainInput = ({type, placeholder, isValid, isDirty, value, onChange, onBlur}) => {
    return (
        <input
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            className={(isDirty && !isValid)?
                'border w-full rounded py-1.5 focus:outline-none px-2 border-red-500 duration-300'
                :
                'border w-full rounded py-1.5 px-2 hover:border-blue-500 focus:outline-none focus:border-blue-500 duration-300'
            }
        />
    );
};
