import React, {useState} from 'react';
import {MainInput, SendButton} from "../../widgets";
import {useInput} from "../../processes/Hooks/InputHooks";
import {regPhoneNumber, regUsername} from "../../shared";
import axios from "axios";
import {SucsessModal} from "../Modal/SucsessModal";

export const CreatTaskTeams = () => {
    const [modalActive, setModalActive] = useState(false)


    const values = [
        {
            type: 'text',
            placeholder: "Имя и фамилия",
            value: useInput('', {isEmpty: true, regExp: regUsername})
        },
        {
            type: 'text',
            placeholder: 'Номер мобильного телефона',
            value: useInput('', {isEmpty: true, regExp: regPhoneNumber})
        }
    ]

    const handlerClick = (e) => {
        e.preventDefault()


        if (values[0].value.isValid && values[1].value.isValid) {
            const baseUrl = `${process.env.REACT_APP_API_URL}/api/info/`;
            axios.post(
                baseUrl, {
                    "ID": 0,
                    "FullName": values[0].value.value,
                    "PhoneNumber": values[1].value.value,
                }
            ).then((res) => {
                values[0].value.ResetInput();
                values[1].value.ResetInput();

                setModalActive(true)
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    return (
        <>

            <form>
                {
                    values.map(({type, placeholder, value}) =>
                        <div className='my-4'>
                            <label>{placeholder}</label>
                            <MainInput
                                type={type}
                                placeholder={placeholder}
                                onBlur={value.onBlur}
                                onChange={value.onChage}
                                value={value.value}
                                isValid={value.isValid}
                                isDirty={value.isDirty}
                            />

                            <ul>
                                <li className='text-xs text-red-500'>
                                    {
                                        value.isDirty && value.isEmpty ?
                                            '• Имя пользователя не может быть пустым'
                                            :
                                            ''
                                    }
                                </li>

                                <li className='text-xs text-red-500'>
                                    {
                                        value.isDirty && !value.regExpErr ?
                                            '• Некоректные данные' :
                                            ''
                                    }
                                </li>
                            </ul>
                        </div>
                    )
                }

                <hr className='mb-4'/>

                <SendButton onClick={(e) => handlerClick(e)}>Отправить</SendButton>

            </form>

            <SucsessModal active={modalActive} setIsActive={setModalActive}/>
        </>
    );
};
