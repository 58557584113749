import React from 'react';
import {useInput} from "../../processes/Hooks/InputHooks";
import {regEmail, regPassword} from "../../shared";
import {MainInput, SendButton} from "../../widgets";
import axios from "axios";

export const AdminLoginForm = () => {
    const values = [
        {
            type: 'text',
            placeholder: "Email",
            value: useInput('', {isEmpty: true, regExp: regEmail})
        },
        {
            type: '',
            placeholder: 'Пароль',
            value: useInput('', {isEmpty: true, regExp: regPassword})
        }
    ]

    const handlerClick = (e) => {
        e.preventDefault()

        if(values[0].value.value && values[1].value.value){
            const baseUrl = `${process.env.REACT_APP_API_URL}/api/admin/auth`
            axios.post(
                baseUrl, {
                    "Email" : values[0].value.value,
                    "Password":values[1].value.value,
                }
            ).then((res) => {
                console.log(res.data)
            }).catch((err) => {
                console.error(err)
            })
        }
    }
    return (
        <form className='border rounded'>
            <div className='px-14 py-10'>
                <div className='text-center'>
                    <h4 className='text-6xl text-blue-500 font-bold mb-2'>
                        5D
                    </h4>
                    <p>Панель админстратора сайта 5D</p>
                </div>

                <div>
                    {
                        values.map(({type, placeholder, value}) =>
                            <div className='my-4'>
                                <label>{placeholder}</label>
                                <MainInput
                                    type={type}
                                    placeholder={placeholder}
                                    onBlur={value.onBlur}
                                    onChange={value.onChage}
                                    value={value.value}
                                    isValid={value.isValid}
                                    isDirty={value.isDirty}
                                />

                                <ul>
                                    <li className='text-xs text-red-500'>
                                        {
                                            value.isDirty && value.isEmpty ?
                                                '• Данное поле не может быть пустым'
                                                :
                                                ''
                                        }
                                    </li>

                                    <li className='text-xs text-red-500'>
                                        {
                                            value.isDirty && !value.regExpErr ?
                                                '• Некоректные данные' :
                                                ''
                                        }
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                </div>

                <hr className='mb-4'/>

                <SendButton onClick={(e) => handlerClick(e)}>Войти</SendButton>
            </div>
        </form>
    );
};
