import React from 'react';

export const CardLead = ({fullName, Subtitle, text, srcImg}) => {
    return (
        <div className={'lg:mr-2'}>
            <div className={"relative w-full"}>
                <img className={"left-0 top-0 h-full w-full"} src={srcImg} alt=""/>
            </div>

            <div>
                <h2 className={'text-2xl font-bold mb-2'}>{fullName}</h2>
                <p className={'text-xl mb-6'}>{Subtitle}</p>

                <p>
                    {text}
                </p>
            </div>
        </div>
    );
};
