import React from 'react';

export const SucsessModal = ({active, setIsActive}) => {
    return (
        <div className={
            active ? 'fixed z-10 top-2 left-0 right-0 grid place-items-center duration-500' :
                'fixed z-10 -top-20 -translate-y-20 left-0 right-0 grid place-items-center duration-500'
        }>
            <div className='bg-green-200 rounded'>
                <div className='flex items-center px-6 py-4'>
                    <div className='mx-2 lg:mx-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6 stroke-green-700">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"/>
                        </svg>
                    </div>

                    <div className='text-green-700 mx-2 lg:mx-4'>
                        <h4 className='font-bold mb-1'>Успешно</h4>
                        <p className='text-xs'>Данная форма была отправлена</p>
                    </div>

                    <div className='mx-2 lg:mx-4' onClick={() => setIsActive(!setIsActive)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6 fill-green-700 stroke-green-700">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};
