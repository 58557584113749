import {useEffect, useState} from "react";

export const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setIsDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChage = (e) => {
        setValue(e.target.value)
    }
    const onBlur = () => {
        setIsDirty(true);
    }

    const ResetInput = () => {
        setValue("")
        setIsDirty(false)
    }


    return{
        value, ResetInput, isDirty, onChage, onBlur, ...valid
    }
}

const useValidation = (value, validation) => {
    const [isEmpty, setIsEmpty] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [regExpErr, setRegExpErr] = useState(false);

    useEffect(() => {
        for (const key in validation){
            switch (key){
                case "isEmpty":
                    value ? setIsEmpty(false) : setIsEmpty(true);
                    break;
                case "regExp":
                    setRegExpErr(validation.regExp.test(value));
                    break;
            }
        }
    }, [validation, value]);

    useEffect(() => {
        if (isEmpty || !regExpErr){
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [isEmpty, regExpErr]);

    return {
        isEmpty, regExpErr, isValid
    }
}