import React from 'react';
import "./style/index.css";
import {RouterIndex} from "../processes";

export const App = () => {
    return (
        <main className='container mx-auto px-4'>
            <RouterIndex/>
        </main>
    );
};
