import React from 'react';

export const SendButton = ({children, onClick, isValid}) => {
    return (
        <button
            className='w-full bg-blue-500 text-white px-2 py-1.5 rounded hover:'
            onClick={onClick}>
            {children}
        </button>
    );
};
