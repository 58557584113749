import React, {useState} from 'react';
import {Link} from "react-router-dom";

export const Header = () => {
    const [isActive, setIsActive] = useState(false)

    return (
        <>
            <header className='flex justify-between items-center my-4'>
                <div>
                    <p className='text-4xl font-bold text-blue-500'>5D</p>
                </div>

                <div className='hidden lg:flex'>
                    <Link className='mx-2 duration-300 hover:text-blue-500' to='/'>Главная</Link>
                    <Link className='mx-2 duration-300 hover:text-blue-500' to='/market'>Магазин</Link>
                    <Link className='mx-2 duration-300 hover:text-blue-500' to='/'>Профиль</Link>
                </div>

                <div className='hidden lg:flex'>
                    <Link to='/log'
                          className='px-4 py-2 bg-blue-500 text-white rounded duration-300 hover:bg-blue-700'>
                        Войти
                    </Link>
                </div>

                <div onClick={() => setIsActive(!isActive)} className='border p-2 rounded block lg:hidden'>
                    {
                        isActive ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                            </svg>
                    }
                </div>
            </header>

            {
                isActive && (
                    <div className='lg:hidden absolute w-full h-48 bg-white'>
                        <div className='flex flex-col mb-2'>
                            <Link className='my-2 duration-300 hover:text-blue-500' to='/'>Главная</Link>
                            <Link className='my-2 duration-300 hover:text-blue-500' to='/market'>Магазин</Link>
                            <Link className='my-2 duration-300 hover:text-blue-500' to='/'>Профиль</Link>
                        </div>

                        <Link to='/log'
                              className='px-4 py-2 bg-blue-500 text-white rounded duration-300 hover:bg-blue-700'>
                            Войти
                        </Link>
                    </div>
                )
            }
        </>
    );
};
