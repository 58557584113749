import React from 'react';
import {AdminLoginForm} from "../../../features";

export const AdminLogin = () => {
    return (
        <div className="h-screen flex items-center justify-center">
            <AdminLoginForm/>
        </div>
    );
};
