import React from 'react';
import {Routes, Route} from "react-router-dom";
import {PublicRoutes} from "../paths";

export const RouterApp = () => {
    return (
        <Routes>
            {
                PublicRoutes.map(
                    el => <Route path={el.path} element={el.element}/>
                )
            }
        </Routes>
    );
};