import React from 'react';
import {CardLead, Header} from "../../widgets";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import {al, ol, sliderImg1, sliderImg2, sliderImg3} from "../../shared";
import {CreatTaskTeams} from "../../features";

import 'swiper/css';

export const Home = () => {
    return (
        <>
            <Header/>
            <hr className="my-4"/>
            <section className='lg:flex lg:items-center'>
                <div className='mb-4 lg:w-1/2'>
                    <h1 className='text-6xl text-blue-500 font-black mb-2'>5D - Личность</h1>
                    <p className='text-2xl mb-5'>
                        Инклюзивный Центр компетенций молодежного
                        предпринимательства “5Д”
                    </p>
                    <p className='lg:w-3/4'>
                        - социальная интегрированная
                        площадка для воплощения твоих инженерных и IT идей,
                        путем организации на одной территории
                        производственной практики, мастер классов и проектной
                        деятельности. Для всех желающих от 6 до 100 лет
                    </p>
                </div>

                <div className='lg:w-1/2'>
                    <Swiper
                        loop={true}
                        className={"rounded"}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        pagination={{
                            clickable: true,
                            renderBullet: (index, className) => {
                                return `<span class="${className}"></span>`
                            }
                        }}
                        modules={[Autoplay, Pagination]}
                    >
                        <SwiperSlide>
                            <img src={sliderImg1} alt=''/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={sliderImg2} alt=''/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={sliderImg3} alt=''/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className='my-6'>
                <div className='lg:flex lg:items-center lg:justify-between'>
                    <div className='lg:mx-10 lg:order-2'>
                        <h2 className='text-6xl text-blue-500 font-black mb-2'>Хочешь к нам в команду?</h2>
                        <p>Оставь заявку, и мы тебе перзвоним</p>
                    </div>
                    <div className='bg-blue-50 rounded lg:w-1/2'>
                        <div className='px-6 py-6 my-4 lg:py-16 lg:my-0'>
                            <CreatTaskTeams/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='my-6 lg:flex lg:items-center'>
                <div className='w-full lg:w-1/2'>
                    <h2 className='text-6xl text-blue-500 font-black mb-6'>
                        Руководители
                    </h2>

                    <p className="mb-4">
                        В данном блоке Вы можете ознакомиться с
                        руководителями проекта “5D”
                    </p>

                    <p className="mb-4">
                        Проект помогает делать первые шаги для
                        личностного роста. Именно здесь, под чутким
                        руководством педагогов, помогающих во всех
                        начинаниях, растут люди, которые, не смотря на
                        свой возраст, уже доказывают, что не боятся
                        проблем и готовы развиваться дальше.
                    </p>
                </div>

                <div className='lg:flex lg:justify-between lg:w-1/2'>
                    <div className={"w-full mb-4 lg:w-1/2"}>
                        <CardLead
                            srcImg={al}
                            fullName={"Тарасова Алина Юрьевна"}
                            Subtitle={"Руководитель ЦКМП “5D”"}
                            text={
                                "Руководитель проектов центр компетенцией\n" +
                                "молодежного предпринимательства “5Д” и центра\n" +
                                "молодежного инновационного творчества \"Имидж\",\n" +
                                "заведующая молодежным (подростковым) клубом\n" +
                                "“Метеор”."
                            }
                        />
                    </div>

                    <div className={'w-full mb-4 lg:w-1/2'}>
                        <CardLead
                            srcImg={ol}
                            fullName={"Понамарева Олеся Юрьевна"}
                            Subtitle={"Руководитель проекта “5Д личность”"}
                            text={
                                "Руководитель проекта “5Д личность” г.Москва\n" +
                                "председатель первичной организации РДШ на базе\n" +
                                "ГБОУ Школа №1195, куратор проектов: \"Московское\n" +
                                "кино в школе\", РДШ, РДДМ, Нового педагогического\n" +
                                "класса в московской школе, клуб Большая перемена"
                            }
                        />
                    </div>
                </div>
            </section>
        </>
    );
};